
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinLaborins from '@/mixins/mixinLaborins';
import global from "@/vuex/laborins/global";
import dialog from "@/vuex/dialog";
import manager from "@/vuex/laborins/manager";
import CommonProgress from "@/components/common/Progress.vue";
import LaborinsTableMain from "@/components/laborins/table/Main.vue";

@Component({
  components: {
    CommonProgress,
    LaborinsTableMain,
  }
})
export default class Main extends Mixins(mixinLaborins) {
  
}
