
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinLaborins from '@/mixins/mixinLaborins';
import dialog from "@/vuex/dialog";
import manager from "@/vuex/laborins/manager";
import CommonButton from "@/components/common/Button.vue";

@Component({
  components: {
    CommonButton,
  }
})
export default class Control extends Mixins(mixinLaborins) {
  created() {
    this.m.setEditFlag({name: 'laborins_masters', val: null});
  }
  erase() {
    this.m.delete({name: 'laborins', args: {laborins_id: this.laborins_id}});
  }
  edit() {
    this.m.startEdit({name: 'laborins_masters', val: true, object: this.yearly.laborins_masters});
  }
  cancel() {
    this.m.finishEdit({name: 'laborins_masters'});
  }
  update() {
    this.m.update({name: 'laborins_masters', args: {laborins_id: this.laborins_id, laborins_masters: this.m.tmp_objects['laborins_masters']}});
  }

  pdf() {
    this.m.document({name: 'renew_laborins_pdf', args: {laborins_id: this.laborins_id, year: this.global.laborins.year}});
  }
}
