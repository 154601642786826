
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinLaborins from "@/mixins/mixinLaborins";
  import global from "@/vuex/laborins/global";
  import dialog from "@/vuex/dialog";
  import manager from "@/vuex/laborins/manager";

  @Component({
    components: {},
  })
  export default class Row extends Mixins(mixinLaborins) {
    @Prop() laborins_master;
  }
