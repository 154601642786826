
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinLaborins from '@/mixins/mixinLaborins';
import dialog from "@/vuex/dialog";
import manager from "@/vuex/laborins/manager";
import CommonButton from "@/components/common/Button.vue";
import LaborinsDialogCreate from "@/components/laborins/dialog/Create.vue";

@Component({
  components: {
    CommonButton,
    LaborinsDialogCreate,
  }
})
export default class Control extends Mixins(mixinLaborins) {
  create_laborins() {
    this.m.openDialog({name: 'create_laborins'});
  }
}
