
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import utilMixins from '@/mixins';
import dialog from "@/vuex/dialog";
import mixinLaborins from '@/mixins/mixinLaborins';
import LaborinsNolaborins from "@/components/laborins/Nolaborins.vue";
import LaborinsMain from "@/components/laborins/Main.vue";

@Component({
  components: {
    LaborinsNolaborins,
    LaborinsMain,
  }
})
export default class Laborins extends Mixins(mixinLaborins) {
}
