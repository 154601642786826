
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinLaborins from "@/mixins/mixinLaborins";
  import global from "@/vuex/laborins/global";
  import dialog from "@/vuex/dialog";
  import manager from "@/vuex/laborins/manager";
  import CommonText from "@/components/common/Text.vue";

  @Component({
    components: {
      CommonText,
    },
  })
  export default class Row extends Mixins(mixinLaborins) {
    @Prop() laborins_master;

    get tmp_laborins_master() {
      console.log(
        this.laborins_master,
        this.laborins_master.id,
        this.m.tmp_objects.laborins_masters,
        this.m.tmp_objects.laborins_masters[this.laborins_master.id],
      );
      return this.m.tmp_objects.laborins_masters[this.laborins_master.id];
    }
    set tmp_laborins_master(val) {
      this.m.setTmpObject({ name: "laborins_masters", key: this.laborins_master.id, val });
    }
  }
