
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import dialog from "@/vuex/dialog";
  import mixinLaborins from "@/mixins/mixinLaborins";
  import * as util from "@/util";
  import UtilPopup from "@/components/util/Popup.vue";
  import CommonYearmonth from "@/components/common/Yearmonth.vue";
  import CommonText from "@/components/common/Text.vue";
  import CommonCheck from "@/components/common/Check.vue";

  @Component({
    components: {
      UtilPopup,
      CommonYearmonth,
      CommonText,
      CommonCheck,
    },
  })
  export default class Dialog extends Mixins(mixinLaborins) {
    dialog_index = "create_laborins";

    created() {
      this.m.closeDialog({ name: this.dialog_index });

      this.m.setTmpObjects({
        name: "laborins",
        val: {
          started_yearmonth: `${util.today_obj().year - 1}-04`,
          ended_yearmonth: `${util.today_obj().year}-03`,
        },
      });

      const branch_ids = Object.keys(this.global.branch_all_hash);
      this.m.setTmpObjects({
        name: "laborins_masters",
        val: [
          {
            estimated_money: null,
            comp_rate: null,
            emp_rate: null,
            emp_rate2: null,
            estimate_comp_rate: null,
            estimate_emp_rate: null,
            estimate_emp_rate2: null,
            branch_ids,
          },
        ],
      });
    }

    create() {
      this.m.create({
        name: "laborins",
        args: {
          laborins: this.tmp_laborins,
          laborins_masters: this.m.tmp_objects["laborins_masters"],
        },
      });
    }

    get tmp_laborins() {
      return this.m.tmp_objects["laborins"];
    }
    set tmp_laborins(val) {
      this.m.setTmpObjects({ name: "laborins", val });
    }
    get tmp_laborins_masters() {
      return this.m.tmp_objects["laborins_masters"];
    }
    set tmp_laborins_masters(val) {
      this.m.setTmpObjects({ name: "laborins_masters", val });
      console.log(this.tmp_laborins_masters);
    }

    change_check(branch_id, group_count, val) {
      if (val.indexOf(branch_id) === -1) {
        //チェックを外した
        if (val.length == 0) {
          //全てチェックを外すことになった。 => 上にそれを入れる
          if (group_count == 0) {
            //でもこれが一番上 => 次のやつに入れる
            this.tmp_laborins_masters[1].branch_ids.push(branch_id);
            console.log(this.tmp_laborins_masters);
          } else {
            //上にやつに入れる
            this.tmp_laborins_masters[group_count - 1].branch_ids.push(branch_id);
          }
        } else {
          //全てのチェックを外すわけではない => したに既にあればそれに入れる　ないなら新しく作る
          if (this.tmp_laborins_masters.length > group_count + 1) {
            this.tmp_laborins_masters[group_count + 1].branch_ids.push(branch_id);
          } else {
            const length = this.tmp_laborins_masters.push({
              estimated_money: null,
              comp_rate: null,
              emp_rate: null,
              emp_rate2: null,
              estimate_comp_rate: null,
              estimate_emp_rate: null,
              estimate_emp_rate2: null,
              branch_ids: [],
            });
            this.tmp_laborins_masters[length - 1].branch_ids.push(branch_id);
          }
        }
      } else {
        for (var i = 0; i < this.tmp_laborins_masters.length; i++) {
          if (i !== group_count) {
            const branch_index = this.tmp_laborins_masters[i].branch_ids.indexOf(branch_id);
            if (branch_index !== -1) {
              //取り除く
              this.tmp_laborins_masters[i].branch_ids.splice(branch_index, 1);
            }
          }
        }
      }

      const admit_masters = [];
      for (var i = 0; i < this.tmp_laborins_masters.length; i++) {
        if (this.tmp_laborins_masters[i].branch_ids.length !== 0) {
          admit_masters.push(this.tmp_laborins_masters[i]);
        }
      }
      this.tmp_laborins_masters = admit_masters;
    }

    get is_display() {
      if (this.m.dialog_flag[this.dialog_index]) {
        return true;
      } else {
        return false;
      }
    }
    set is_display(val: boolean) {
      if (val) {
        this.m.openDialog({ name: this.dialog_index });
      } else {
        this.m.closeDialog({ name: this.dialog_index });
      }
    }
  }
