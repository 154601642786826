import { Component, Vue, Mixins } from 'vue-property-decorator';
import utilMixins from '@/mixins';
import {makeMixin, makeUtilMixin} from '@/mixins/Descriptor';
import vuex_data from '@/vuex/vuex_data';
import vuex_manager from '@/vuex/vuex_manager';
import * as util from '@/util';

const utilDescriptor = {
  manager: vuex_manager.laborins_manager,
  data: [],
}
const globalDescriptor = {
  name: 'global',
  vuex: vuex_data.laborins_global,
  indexers: ['laborins_id'],
  fetch(self) {
    return {};
  },
  dependencies: [],
};
const yearlyDescriptor = {
  name: 'yearly',
  vuex: vuex_data.laborins_yearly,
  indexers: ['laborins_id'],
  fetch(self) {
    return { laborins_id: self.laborins_id };
  },
  dependencies: ['global'],
};


//これは時代遅れの指定方法　mixinsの上限がある方法　mixinUpdateStdincomeの方法が正しい
@Component
export default class mixinLaborins extends Mixins(makeMixin(globalDescriptor), makeMixin(yearlyDescriptor), makeUtilMixin(utilDescriptor), utilMixins) {
  get laborins_id() {
    if (this.param_laborins_id == 0) {
      this.param_laborins_id = this.global_latest_data.latest_id;
      return this.global_latest_data.latest_id;
    } else {
      return this.param_laborins_id;
    }
  }
  set laborins_id(val) {
    this.param_laborins_id = val;
  }
  get param_laborins_id() {
    var laborins_id = this.query.laborins_id;
    if (laborins_id === undefined) {
      laborins_id = 0;
      this.query = {laborins_id};
    }
    return laborins_id;
  }
  set param_laborins_id(val) {
    if (val != this.param_laborins_id) {
      this.query_push = {laborins_id: val};
    }
  }

  get payment_index() {
    var index = [];
    var startobj = util.date2obj(this.global.laborins.started_yearmonth+"-01");
    var endobj = util.date2obj(this.global.laborins.ended_yearmonth+"-01");
    for (; startobj.getTime() <= endobj.getTime(); startobj = util.next_month(startobj)) {
      index.push(util.obj2year(startobj)+'年'+util.obj2month(startobj)+'月');
    }
    return index;
  }
}
